import { TimeRange } from "../models/common";
import { MSEC_PER_DAY } from "./constant";
import moment from "moment";

export const getUTCMidnight: (date: Date) => Date = (date: Date) => {
    const newDate = new Date(date);
    newDate.setUTCHours(0, 0, 0, 0);
    return newDate;
};

export const getLatestUTCTimeInDay: (date: Date) => Date = (date: Date) => {
    const newDate = new Date(date);
    newDate.setUTCHours(23, 59, 59);
    return newDate;
};

const get24HoursRounded: (date: Date) => Date = (date: Date) => {
    const newDate = new Date(date);
    newDate.setUTCHours(newDate.getUTCHours() - 23, 0, 0, 0);
    return newDate;
};

export const getStartDate = (timeRange: TimeRange) => {
    let startDate;
    const endDate = Date.now();

    switch (timeRange) {
        case TimeRange.TwentyEightDays:
            startDate = getUTCMidnight(new Date(endDate - MSEC_PER_DAY * 27));
            break;
        case TimeRange.SevenDays:
            startDate = getUTCMidnight(new Date(endDate - MSEC_PER_DAY * 6));
            break;
        case TimeRange.Today:
            startDate = get24HoursRounded(new Date(endDate));
            break;
        default:
            startDate = undefined;
            break;
    }

    return startDate;
};

export const guessTimeZone = () => {
    return moment().tz(moment.tz.guess()).format("z");
};

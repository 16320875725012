import { LocalizedUrls } from "@amzn/ziggy-asset";
import { TimeRange } from "../models/common";
import { getLocalizedUrl } from "./localization";
export const MSEC_PER_DAY = 24 * 60 * 60 * 1000;
export const TOKEN_REFRESH_INTERVAL = 15 * 60 * 1000;
export const SUMMARY_TABLE_ITEM_COUNT = 5;
export const ADD_TO_PLAYLIST_TABLE_ITEM_COUNT = 2;
export const DEFAULT_TIME_RANGE = TimeRange.SevenDays;
export const MIN_DATE = new Date("1/1/2018");

export const LEARN_MORE_ABOUT_CLAIMS_URL =
    getLocalizedUrl(LocalizedUrls.ArtistHandbookFAQLink) +
    "#faq-question-im-having-trouble-claiming-my-artist-what-should-i-do";
export const TWITCH_SIGNUP_URL = "https://www.twitch.tv/signup";
export const APP_STORE_URL =
    "https://apps.apple.com/us/app/amazon-music-for-artists/id1475021574";
export const PLAY_STORE_URL =
    "https://play.google.com/store/apps/details?id=com.amazon.ziggy.android";
export const HYDRATION_COUNT = 20;

export const GENERIC_TEXT_FIELD_LIMIT = 100;
export const SEARCH_TEXT_FIELD_LIMIT = 255;
export const MOREINFO_TEXT_FIELD_LIMIT = 320;

// Setting resultSize to 10, this prevents the see all buttons not showing in Fans tab.
export const DEMOGRAPHICS_QUERY_RESULT_SIZE = 10;
export const DEMOGRAPHICS_ITEM_COUNT = 50;
export const DEMOGRAPHICS_ITEM_QUERY_COUNT = 70;

export const PROFILE_IMAGE_MIN_WIDTH = 500;
export const PROFILE_IMAGE_MIN_HEIGHT = 500;
export const PROFILE_IMAGE_WIDTH = 1000;
export const PROFILE_IMAGE_HEIGHT = 1000;
export const PROFILE_IMAGE_MAX_BYTES = 5242880;

export const BACKGROUND_IMAGE_MIN_WIDTH = 1920;
export const BACKGROUND_IMAGE_MIN_HEIGHT = 1440;
export const BACKGROUND_IMAGE_WIDTH = 3840;
export const BACKGROUND_IMAGE_HEIGHT = 2880;
export const BACKGROUND_IMAGE_MAX_BYTES = 10485760;

export const IMAGE_COMPRESSION = 0.8;

// Byte Conversion numbers.  works the same for bits (bits in kilobit) etc...
export const BYTES_IN_KILOBYTE = 1024;
export const BYTES_IN_MEGABYTE = 1024 * 1024;
export const BYTES_IN_GIGABYTE = 1024 * 1024 * 1024;

export const MOBILE_SCREEN_MAX_WIDTH = 575;
export const LARGE_SCREEN_WIDTH_THRESHOLD = 992;

export const US_MARKETPLACE = "ATVPDKIKX0DER";
// Regex expressions for ASIN
export const ASIN_FORMAT = "([0-9]{9}[0-9X]|B[0-9A-Z]{9})";

export const paths = {
    claim: "/claim/start",
    claimDone: "/claim/done",
    requesterType: "/claim/requesterType",
    vendorFlow: "/claim/vendor",
    vendorFlowLearnMore: "/claim/vendor/learn-more",
    artistSearch: "/claim/search",
    provideInformation: "/claim/provide",
    profile: "/profile",
    newReleases: "/newReleases",
    pitch: "/pitch",
    reports: "/reports/overview",
    albums: "/reports/albums",
    songs: "/reports/songs",
    songPerformance: "/reports/song",
    playlistPerformance: "/reports/playlist",
    stationPerformance: "/reports/station",
    albumPerformance: "/reports/album",
    artistSelect: "/select",
    currentTeamMembers: "/team/current",
    invitedTeamMembers: "/team/invited",
    inviteTeamMember: "/team/invite",
    manageTeamMember: "/team/manage",
    checkTeamExists: "/team/checkTeamExists",
    playlists: "/reports/playlists",
    newAddsToPlaylists: "/reports/newAddsToPlaylists",
    stations: "/reports/stations",
    voice: "/reports/voice",
    programming: "/reports/programming",
    home: "/",
    fans: "/reports/fans",
    countries: "/reports/countries",
    albumTracks: "/reports/albumtracks",
    songCountries: "/reports/songcountries",
    albumCountries: "/reports/albumcountries",
    stationCountries: "/reports/stationcountries",
    playlistCountries: "/reports/playlistcountries",
    settings: "/settings",
    termsAndConditions: "/settings/termsAndConditions",
    oobe: "/oobe",
    acceptInvite: "/acceptInvite",
    openSourceAttribution: "/openSourceAttribution",
    optOut: "/optOut",
    reportArtistProfile: "/reportArtistProfile",
    initialFlow: "",
    blueprints: "/spotlight",
    accountDetails: "/account/details",
    announcements: "/studio/announcements",
    announcementDetails: "/studio/announcementDetails",
    signOut: "/signOut",
    merch: "/merch/overview",
    modLanding: "/merch/merchOnDemand",
    autoSearchMerch: "/merch/suggested",
    manualSearchMerch: "/merch/search",
    seeAllMerch: "/merch/seeAll",
    promoCard: "/artistShare/promoCard",
    deeplinkResolver: "/link",
};

export const claimPathsPrefix = "claim/";

export const COULD_NOT_LOAD_DATA_SUFFIX = "CouldNotLoadData";

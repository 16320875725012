import * as React from "react";
import { Dropdown, Col, Row } from "react-bootstrap";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import "moment/min/locales";
import { getLocalizedString } from "../../../utils/stringHelper";
import { stringIds, bundleIds } from "../../../assets/strings";
import * as rootStyles from "../../styles";
import { BundleMap, TimeRange, timeRangePayload } from "../../../models";
import { IconsList, Icon, StyledButtons } from "../common";
import { MSEC_PER_DAY, webLocale, MIN_DATE } from "../../../utils";
import { dropDownStyles } from "../../styles/buttonStyles";
import { reportingActions } from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import CustomDatePicker from "./CustomDatePicker";

type ViewProps = { idOverride?: string };

type StateProps = {
    bundleMap: BundleMap;
    selectedRange?: TimeRange;
    signedIn: boolean;
    selectedArtist?: string;
    startDate?: Date;
    endDate?: Date;
    userLocale: string;
};

type DispatchProps = {
    updateTimeRange?: (payload: timeRangePayload) => void;
};

export type TimeRangePickerProps = ViewProps & DispatchProps & StateProps;

type State = {
    showCustomPicker: boolean;
    confirmDisabled: boolean;
    startDate: Date;
    endDate: Date;
};

type RangeItem = {
    icon: any;
    text: string;
    range: TimeRange;
};

export class TimeRangePickerClass extends React.PureComponent<
    TimeRangePickerProps,
    State
> {
    constructor(props: TimeRangePickerProps) {
        super(props);
        this.state = {
            showCustomPicker: false,
            confirmDisabled: false,
            startDate: this.props.startDate || this.getDefaultStartDate(),
            endDate: this.props.endDate || this.getDefaultEndDate(),
        };
    }

    render() {
        const dateStringFormat = "MMM D, YYYY";
        const startDateString = moment(this.state.startDate)
            .locale(webLocale[0])
            .format(dateStringFormat);
        const endDateString = moment(this.state.endDate)
            .locale(webLocale[0])
            .format(dateStringFormat);
        console.log(
            `${moment(this.state.startDate).format("MMM D, YYYY")} - ${moment(
                this.state.endDate
            ).format(dateStringFormat)}`
        );

        const id = this.props.idOverride || "timepicker";
        return (
            <div style={{ width: "100%" }}>
                <Dropdown
                    style={dropDownStyles.dropdown}
                    className="rightPointer"
                    alignRight={true}
                >
                    <Dropdown.Toggle
                        as={StyledButtons.MediumGlass}
                        id={id}
                        variant="secondary"
                    >
                        <Row>
                            <Col
                                style={{
                                    padding: 0,
                                    lineHeight: `${rootStyles.spacers.medium}px`,
                                }}
                            >
                                <span
                                    style={{
                                        ...rootStyles.textStyles.primary,
                                        paddingRight: rootStyles.spacers.mini,
                                    }}
                                >
                                    {this.props.selectedRange ===
                                    TimeRange.Custom
                                        ? `${startDateString} - ${endDateString}`
                                        : this.getDatePickerString(
                                              this.props.selectedRange ||
                                                  TimeRange.SevenDays
                                          )}
                                </span>
                            </Col>
                            <Col
                                xs={"auto"}
                                style={{
                                    display: "flex",
                                    padding: 0,
                                    alignItems: "center",
                                }}
                            >
                                <Icon
                                    size={rootStyles.spacers.medium}
                                    icon={IconsList.chevron_caretdown}
                                    id={`${id}_SelectIcon`}
                                />
                            </Col>
                        </Row>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={dropDownStyles.dropdownMenu}>
                        {this.getItems(id)}
                    </Dropdown.Menu>
                </Dropdown>
                {this.state.showCustomPicker ? (
                    <CustomDatePicker
                        initialStartDate={this.state.startDate}
                        initialEndDate={this.state.endDate}
                        hideModal={this.cancelCustomPicker}
                        saveDates={this.acceptCustomPicker}
                        minDate={MIN_DATE}
                        maxDate={new Date(Date.now())}
                        confirmDisabled={this.state.confirmDisabled}
                        withinRange={this.dateDiffCheck}
                        rangeMessage={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.REPORTS_STRINGS,
                            stringId: stringIds.Reports.calendarRangeMessage,
                        })}
                        rangeError={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.REPORTS_STRINGS,
                            stringId: stringIds.Reports.calendarRangeError,
                        })}
                        id={`${id}_CustomDatePicker`}
                    />
                ) : null}
            </div>
        );
    }

    private dateDiffCheck = (startDate: Date, endDate: Date) => {
        const oneYearDiff =
            (endDate.valueOf() - startDate.valueOf()) / (1000 * 3600 * 24);
        if (oneYearDiff > 365) {
            this.setState({ confirmDisabled: true });
        } else {
            this.setState({ confirmDisabled: false });
        }
    };

    private cancelCustomPicker = () => {
        this.setState({
            showCustomPicker: false,
        });
    };

    private acceptCustomPicker = (startDate: Date, endDate: Date) => {
        const newStartDate = this.snapDate(new Date(startDate));
        const newEndDate = this.snapDate(new Date(endDate));

        this.setState({
            showCustomPicker: false,
        });

        if (this.props.updateTimeRange) {
            this.props.updateTimeRange({
                timeRange: TimeRange.Custom,
                startDate: newStartDate,
                endDate: newEndDate,
            });
        }
    };

    /**
     * If necessary, snaps the datetime and the UTC datetime to the same day/month, so that both the UI
     * and the backend will be able to interpret the date correctly. The UI needs the correct local date, and the backend
     * needs the correct UTC date.
     */
    private snapDate = (date: Date) => {
        const newDate = new Date(date);

        // East of UK
        if (newDate.getTimezoneOffset() < 0) {
            newDate.setHours(0, -newDate.getTimezoneOffset(), 0, 0);
            // West of UK
        } else {
            newDate.setHours(0, 0, 0, 0);
        }

        return newDate;
    };

    private getDefaultStartDate = () => {
        return this.snapDate(new Date(Date.now() - MSEC_PER_DAY * 7));
    };

    private getDefaultEndDate = () => {
        return this.snapDate(new Date());
    };

    private getItems = (id: string) => {
        const rangeSelected = (item: RangeItem) => {
            if (item.range === TimeRange.Custom) {
                this.dateDiffCheck(this.state.startDate, this.state.endDate);
                this.setState({
                    showCustomPicker: true,
                });
            } else {
                if (this.props.updateTimeRange) {
                    this.props.updateTimeRange({
                        timeRange: item.range,
                    });
                }
            }
        };

        const RangeOptions: RangeItem[] = [
            {
                text: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.timeRangeAllTime,
                }),
                range: TimeRange.AllTime,
                icon: IconsList.ic_other_calendar,
            },
            {
                text: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.timeRangeToday,
                }),
                range: TimeRange.Today,
                icon: IconsList.ic_other_calendar1,
            },
            {
                text: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.timeRangeSevenDays,
                }),
                range: TimeRange.SevenDays,
                icon: IconsList.ic_other_calendar7,
            },
            {
                text: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.timeRangeTwentyEightDays,
                }),
                range: TimeRange.TwentyEightDays,
                icon: IconsList.ic_other_calendar28,
            },
            {
                text: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.timeRangeCustom,
                }),
                range: TimeRange.Custom,
                icon: IconsList.ic_other_calendaradd,
            },
        ];

        return RangeOptions.map((item: RangeItem, index: number) => {
            return (
                <Dropdown.Item
                    key={item.text}
                    eventKey={item.text}
                    onSelect={() => rangeSelected(item)}
                    style={dropDownStyles.dropdownItem}
                >
                    <Row>
                        <div style={{ marginRight: rootStyles.spacers.small }}>
                            <Icon
                                icon={item.icon}
                                size={24}
                                id={`${id}_${index}`}
                            />
                        </div>
                        {item.text}
                    </Row>
                </Dropdown.Item>
            );
        });
    };

    private keyExtractor = (_item: string, index: number) => index.toString();

    private getDatePickerString = (range: TimeRange): string => {
        switch (range) {
            case TimeRange.TwentyEightDays:
                return getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.timeRangeTwentyEightDays,
                });
            case TimeRange.SevenDays:
                return getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.timeRangeSevenDays,
                });
            case TimeRange.Today:
                return getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.timeRangeToday,
                });
            case TimeRange.Custom:
                return getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.timeRangeCustom,
                });
            default:
                return getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.timeRangeAllTime,
                });
        }
    };
}

function mapStateToProps(state: RootState): StateProps {
    return {
        startDate: state.reporting.startDate,
        endDate: state.reporting.endDate,
        selectedRange: state.reporting.selectedRange,
        signedIn: state.user.signedIn,
        userLocale: state.user.locale,
        bundleMap: state.localization.bundleMap,
    };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        updateTimeRange: (payload: timeRangePayload) =>
            dispatch(reportingActions.updateTimeRange(payload)),
    };
}

export const TimeRangePicker = connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeRangePickerClass);

const dropDownToggleStyle: React.CSSProperties = {
    ...dropDownStyles.dropdownButton,
    paddingRight: rootStyles.spacers.small,
    paddingTop: 0,
    paddingBottom: 0,
};
